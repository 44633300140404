import { HttpLink } from "apollo-angular/http";
import { ErrorHandlerService } from "./error-graphlq.service";
import { ApolloLink, InMemoryCache } from "@apollo/client/core";
import { environment } from "src/environments/environment";
import { typePolicies } from "./cache.graphql";
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS } from "apollo-angular";

export function createApollo(httpLink: HttpLink, errorHandler: ErrorHandlerService) {
  const errorLink = errorHandler.getErrorLink();
  const http = httpLink.create({
    uri: environment.platform_graphql,
    withCredentials: true,
  });
  return {
    PlatformApi: {
      link: ApolloLink.from([errorLink, http]),
      cache: new InMemoryCache({
        typePolicies: {
          ...typePolicies,
        },
      }),
      ssrMode: true,
      ssrForceFetchDelay: 100,
      connectToDevTools: true,
    }
  };
}

export const apolloProvider = {
  provide: APOLLO_NAMED_OPTIONS,
  useFactory: createApollo,
  deps: [HttpLink, ErrorHandlerService],
};
