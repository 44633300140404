
import { onError } from '@apollo/client/link/error';
import { inject, Injectable } from '@angular/core';
import { ApolloClientErrorCodes } from './errors';
import { PlatformService } from 'ngx-unificator/services';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {

  private _platform: PlatformService = inject(PlatformService);

  getErrorLink() {
    return onError(({ graphQLErrors, networkError }) => {
      console.log(graphQLErrors, networkError );

      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          if (error.extensions?.code === ApolloClientErrorCodes.UNAUTHORIZED && this._platform.isBrowser) {
            window.location.href = '/login';
          }
        }
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`);
      }
    });
  }

}
